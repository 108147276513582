<template>
  <v-container
    fluid
    style="height: 100%;"
  >
    <v-row
      wrap
      class="align-center justify-center"
      style="height: 100%;"
    >
      <v-col
        cols="12"
        sm="7"
      >
        <v-banner
          v-show="bannerVisible"
          transition="fade-transition"
          dark
        >
          <v-avatar
            slot="icon"
            color="shamrock"
            size="40"
          >
            <v-icon
              icon="mdi-lock"
              color="white"
            >
              mdi-rocket
            </v-icon>
          </v-avatar>

          <p class="mb-0">
            Welcome to the new Pathfinder app. We've imported some fields from your legacy project below.
          </p>

          <template v-slot:actions>
            <v-btn
              text
              color="shamrock"
              @click="bannerVisible = false"
            >
              Got it, thanks
            </v-btn>
          </template>
        </v-banner>
        <ValidationObserver
          ref="observer"
          v-slot="{ handleSubmit, invalid }"
        >
          <v-form
            dark
            color="white"
            autocomplete="off"
            @submit.prevent="handleSubmit(createProject)"
          >
            <v-col
              xs12
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Title"
                rules="required|max:70"
              >
                <v-text-field
                  v-model="title"
                  :error-messages="errors"
                  data-vv-name="title"
                  label="Title"
                  dark
                  class="white--text"
                />
              </ValidationProvider>

              <v-textarea
                v-model="description"
                label="Description"
                dark
                auto-grow
                rows="5"
                class="white--text zoom"
              />
              <ValidationProvider
                v-slot="{ errors }"
                name="Project Type"
                rules="required"
              >
                <v-select
                  v-model="type"
                  :error-messages="errors"
                  data-vv-name="project type"
                  label="Project type"
                  hint="What best describes your project?"
                  persistent-hint
                  required
                  flat
                  dark
                  class="mb-8"
                  item-title="text"
                  item-value="name"
                  :items="sortedProjectTypes"
                />
              </ValidationProvider>
              <v-menu
                ref="menu"
                v-model="showMenu"
                :close-on-content-click="false"
                :return-value.sync="completionDate"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    :value="completionDate"
                    label="Estimated completion date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dark
                    v-on="on"
                  />
                </template>
                <CompletionDatePicker
                  :dark="true"
                  back-in-time
                  @save="e => $refs.menu.save(e)"
                  @close="showMenu = false"
                />
              </v-menu>
              <ValidationProvider
                v-slot="{ errors }"
                name="Units"
                rules="required"
              >
                <v-select
                  v-model="systemOfMeasurement"
                  :error-messages="errors"
                  data-vv-name="units"
                  label="Units"
                  hint="Will you be working with metric or imperial units?"
                  persistent-hint
                  class="pt-2 pb-4"
                  flat
                  dark
                  :items="['imperial', 'metric']"
                />
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                name="Current Phase"
                rules="required"
              >
                <v-select
                  v-model="currentPhase"
                  :error-messages="errors"
                  data-vv-name="current phase"
                  label="What phase is your project in?"
                  persistent-hint
                  class="mt-4"
                  flat
                  dark
                  :items="['Concept', 'Schematic', 'Design Development', 'Construction Drawings', 'Construction', 'Existing Condition']"
                />
              </ValidationProvider>
              <div class="d-flex align-center flex-wrap">
                <div
                  class="leaf--text mr-5"
                  style="opacity: 0.7;"
                >
                  Is your project pursuing LEED or SITES certification?
                </div>
                <div class="d-flex align-center">
                  <v-checkbox
                    v-model="certifications"
                    label="LEED"
                    value="LEED"
                    flat
                    dark
                    class="mr-5"
                  />
                  <v-checkbox
                    v-model="certifications"
                    label="SITES"
                    value="SITES"
                    flat
                    dark
                  />
                </div>
              </div>
              <ValidationProvider
                v-slot="{ errors }"
                name="Is Study"
                rules="required"
              >
                <v-checkbox
                  v-model="isStudy"
                  :error-messages="errors"
                  data-vv-name="isStudy"
                  label="Is this an academic study or project not expected to be built?"
                  hint="Leave this unchecked if this is a real project."
                  class="mt-4"
                  flat
                  dark
                />
              </ValidationProvider>
            </v-col>

            <v-col>
              <v-btn
                color="white elevation-0 px-5 text-uppercase mt-4 "
                large
                block
                :outlined="!!invalid"
                class=""
                type="submit"
              >
                <span :style="{'color': !invalid ? colors.theme : 'white' }">
                  {{ $store.state.isOnboarding ? 'Create project' : 'Calculate footprint' }}
                </span>
              </v-btn>
            </v-col>
          </v-form>
        </ValidationObserver>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import colors from '@/schema/colors'

import CompletionDatePicker from '@/components/atoms/CompletionDatePicker'

import { createNamespacedHelpers, mapGetters as mapGlobalGetters, mapActions, mapMutations } from 'vuex'
import { localize, ValidationObserver, ValidationProvider } from 'vee-validate'
const { mapGetters: mapProjectGetters, mapMutations: mapProjectMutations } = createNamespacedHelpers('projects')

export default {
  name: 'NewProject',
  components: {
    CompletionDatePicker,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      showMenu: false,
      dictionary: {
        fields: {
          Title: {
            required: () => "Don't forget to add a title"
          },
          'Current Phase': {
            required: () => 'Please let us know which phase your project is in'
          }
        }
      },
      colors,
      bannerVisible: false
    }
  },
  computed: {
    ...mapGlobalGetters(['projectTypes', 'userId']),
    ...mapProjectGetters(['newProject']),

    /**
     * The project types are sorted on the server
     * @returns {*}
     */
    sortedProjectTypes () {
      const projectTypesWithText = this.projectTypes?.map((projectType) => ({
        name: projectType.name,
        text: this.$t('project.types.' + projectType.name)
      }))
      return projectTypesWithText
    },
    completionDate: {
      get () {
        const d = new Date(this.newProject.completionDate)
        return `${d.getFullYear()}-${('0' + (d.getMonth() + 1)).slice(-2)}`
      },
      set (val) {
        this.updateNewProject({ completionDate: val })
      }
    },
    ...Object.fromEntries(['systemOfMeasurement', 'type', 'title', 'description', 'currentPhase', 'certifications', 'isStudy'].map(fieldName => (
      [fieldName, {
        get () {
          return this.newProject[fieldName]
        },
        set (val) {
          this.updateNewProject({ [fieldName]: val })
        }
      }]
    )))
  },
  created () {
    this.updateNewProject({ user: this.userId })
    localize('en', this.dictionary)
    const q = new URLSearchParams(window.location.search)
    if (q.has('migrate')) {
      this.bannerVisible = true
      q.delete('migrate')
      q.forEach((value, key) => {
        if (key === 'centroid') {
          key = 'location'
          value = { centroid: JSON.parse(value) }
        }
        this.updateNewProject({ [key]: value })
      })
    }
  },
  methods: {
    ...mapMutations(['setSystemOfMeasurement']),
    ...mapProjectMutations(['updateNewProject', 'resetNewProject', 'setProject']),
    ...mapActions(['showSnackbar']),

    async createProject () {
      try {
        const res = await this.$axios.post('/projects', this.newProject)
        this.setSystemOfMeasurement(this.newProject.systemOfMeasurement)
        this.setProject(res.data)
        if (!this.$store.state.isOnboarding) {
          this.$router.push(`/projects/${res.data._id}/location`, () => {
            this.resetNewProject()
          })
        } else {
          // if project already has a location then redirect straight to the summary page
          this.$router.push(`/projects/${res.data._id}`, () => {
            this.resetNewProject()
          })
        }
      } catch (err) {
        this.showSnackbar({ color: 'error', text: 'Whoops, something went wrong. Please try again.' })
        throw err
      }
    },

    clear () {
      this.title = ''
      this.description = ''
      this.type = ''
      this.$refs.observer.reset()
    }

  }
}
</script>

<style lang="scss">
</style>
