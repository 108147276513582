var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{staticClass:"align-center justify-center",staticStyle:{"height":"100%"},attrs:{"wrap":""}},[_c('v-col',{attrs:{"cols":"12","sm":"7"}},[_c('v-banner',{directives:[{name:"show",rawName:"v-show",value:(_vm.bannerVisible),expression:"bannerVisible"}],attrs:{"transition":"fade-transition","dark":""},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('v-btn',{attrs:{"text":"","color":"shamrock"},on:{"click":function($event){_vm.bannerVisible = false}}},[_vm._v(" Got it, thanks ")])]},proxy:true}])},[_c('v-avatar',{attrs:{"slot":"icon","color":"shamrock","size":"40"},slot:"icon"},[_c('v-icon',{attrs:{"icon":"mdi-lock","color":"white"}},[_vm._v(" mdi-rocket ")])],1),_c('p',{staticClass:"mb-0"},[_vm._v(" Welcome to the new Pathfinder app. We've imported some fields from your legacy project below. ")])],1),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"dark":"","color":"white","autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.createProject)}}},[_c('v-col',{attrs:{"xs12":""}},[_c('ValidationProvider',{attrs:{"name":"Title","rules":"required|max:70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"white--text",attrs:{"error-messages":errors,"data-vv-name":"title","label":"Title","dark":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})]}}],null,true)}),_c('v-textarea',{staticClass:"white--text zoom",attrs:{"label":"Description","dark":"","auto-grow":"","rows":"5"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('ValidationProvider',{attrs:{"name":"Project Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-8",attrs:{"error-messages":errors,"data-vv-name":"project type","label":"Project type","hint":"What best describes your project?","persistent-hint":"","required":"","flat":"","dark":"","item-title":"text","item-value":"name","items":_vm.sortedProjectTypes},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}],null,true)}),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.completionDate,"transition":"scale-transition","offset-y":"","min-width":"290px"},on:{"update:returnValue":function($event){_vm.completionDate=$event},"update:return-value":function($event){_vm.completionDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"value":_vm.completionDate,"label":"Estimated completion date","prepend-icon":"mdi-calendar","readonly":"","dark":""}},on))]}}],null,true),model:{value:(_vm.showMenu),callback:function ($$v) {_vm.showMenu=$$v},expression:"showMenu"}},[_c('CompletionDatePicker',{attrs:{"dark":true,"back-in-time":""},on:{"save":function (e) { return _vm.$refs.menu.save(e); },"close":function($event){_vm.showMenu = false}}})],1),_c('ValidationProvider',{attrs:{"name":"Units","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"pt-2 pb-4",attrs:{"error-messages":errors,"data-vv-name":"units","label":"Units","hint":"Will you be working with metric or imperial units?","persistent-hint":"","flat":"","dark":"","items":['imperial', 'metric']},model:{value:(_vm.systemOfMeasurement),callback:function ($$v) {_vm.systemOfMeasurement=$$v},expression:"systemOfMeasurement"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Current Phase","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mt-4",attrs:{"error-messages":errors,"data-vv-name":"current phase","label":"What phase is your project in?","persistent-hint":"","flat":"","dark":"","items":['Concept', 'Schematic', 'Design Development', 'Construction Drawings', 'Construction', 'Existing Condition']},model:{value:(_vm.currentPhase),callback:function ($$v) {_vm.currentPhase=$$v},expression:"currentPhase"}})]}}],null,true)}),_c('div',{staticClass:"d-flex align-center flex-wrap"},[_c('div',{staticClass:"leaf--text mr-5",staticStyle:{"opacity":"0.7"}},[_vm._v(" Is your project pursuing LEED or SITES certification? ")]),_c('div',{staticClass:"d-flex align-center"},[_c('v-checkbox',{staticClass:"mr-5",attrs:{"label":"LEED","value":"LEED","flat":"","dark":""},model:{value:(_vm.certifications),callback:function ($$v) {_vm.certifications=$$v},expression:"certifications"}}),_c('v-checkbox',{attrs:{"label":"SITES","value":"SITES","flat":"","dark":""},model:{value:(_vm.certifications),callback:function ($$v) {_vm.certifications=$$v},expression:"certifications"}})],1)]),_c('ValidationProvider',{attrs:{"name":"Is Study","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-checkbox',{staticClass:"mt-4",attrs:{"error-messages":errors,"data-vv-name":"isStudy","label":"Is this an academic study or project not expected to be built?","hint":"Leave this unchecked if this is a real project.","flat":"","dark":""},model:{value:(_vm.isStudy),callback:function ($$v) {_vm.isStudy=$$v},expression:"isStudy"}})]}}],null,true)})],1),_c('v-col',[_c('v-btn',{attrs:{"color":"white elevation-0 px-5 text-uppercase mt-4 ","large":"","block":"","outlined":!!invalid,"type":"submit"}},[_c('span',{style:({'color': !invalid ? _vm.colors.theme : 'white' })},[_vm._v(" "+_vm._s(_vm.$store.state.isOnboarding ? 'Create project' : 'Calculate footprint')+" ")])])],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }