<template>
  <ValidationProvider
    v-slot="{ errors }"
    name="Completion"
    rules="required"
  >
    <v-date-picker
      ref="datepicker"
      v-model="date"
      :error-messages="errors"
      data-vv-name="completion date"
      :landscape="$vuetify.breakpoint.smAndUp"
      type="month"
      :close-on-content-click="false"
      :return-value.sync="parsedDate"
      :dark="dark"
      :min="backInTime ? null : new Date().toISOString().substr(0, 10)"
      color="shamrock"
    >
      <v-spacer />
      <v-btn
        text
        :dark="dark"
        @click="handleClose()"
      >
        Cancel
      </v-btn>
    </v-date-picker>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate'

export default {
  name: 'CompletionDatePicker',
  components: {
    ValidationProvider
  },
  props: {
    initialDate: {
      type: String,
      default: () => new Date().toISOString().substr(0, 7)
    },
    dark: {
      type: Boolean,
      default: false
    },
    backInTime: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      date: null
    }
  },
  computed: {
    parsedDate () {
      if (!this.date) return null
      const [year, month] = this.date.split('-')
      return new Date(year, month - 1, 1)
    }
  },
  watch: {
    parsedDate (val) {
      if (val) {
        this.$emit('save', val)
      }
    }
  },
  created () {
    this.date = this.initialDate
  },
  methods: {
    handleClose () {
      this.date = this.initialDate
      this.$emit('close')
    }
  }
}
</script>

<style>

</style>
